import React, { useEffect, useState, useRef } from 'react';
import './Hero.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';


import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';




import Modena from '/assets/img/animations/webm/modena.webm';
import photo2 from '/assets/img/exclusief/col/epoque/webp/20240724-EpoqueScene3-rrpf-cam9-04.webp';
import photo3 from '/assets/img/main/beat40/Thebalux-Ribbed-Navarro-Eiken.webp';
import animation23 from '/assets/img/animations/webm/epoque.webm';

gsap.registerPlugin(ScrollTrigger);

const Hero = () => {
  const [slideIndex, setSlideIndex] = useState(1);
  const [isTextOpen, setIsTextOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const slideTimeout = useRef(null);
  const slidesRef = useRef([]);
  const dotsRef = useRef([]);

  const srcVideos = [
    {
      src: Modena,
      link: "/collecties/spiegels",
      title: "Luxe ochtendrituelen beginnen hier.",
      description: "Ontdek 32 nieuwe spiegels die uw badkamer naar een hoger niveau tillen.",
      buttonLabel: "Bekijk onze spiegelcollectie",
      duration: 4000, 
    },
    {
      src: photo3,
      link: "/collecties",
      title: "Vakmanschap, van Nederlandse bodem",
      description: "Al meer dan 25 jaar staat Thebalux voor kwaliteit. Onze badkamermeubelen worden geproduceerd in onze eigen productiefaciliteit in Biddinghuizen.",
      buttonLabel: "Bekijk de collecties",
      duration: 7000, 
    },
    {
      src: animation23,
      link: "/collecties/exclusief/epoque",
      title: "Organisch, tijdloos en ongelofelijk duurzaam.",
      description: "Gecreerd voor jarenlange pracht in uw badkamer, de Epoque collectie geeft je badkamer stijl en klasse.",
      buttonLabel: "Bekijk de Epoque collectie",
      duration: 16500, 
    },
   
    {
      src: photo2,
      link: "/collecties/exclusief",
      title: "Kwaliteit, binnen handbereik.",
      description: "Een compleet nieuwe collectie, van een niet te evenaren kwaliteit.",
      buttonLabel: "Bekijk Thebalux Exclusief",
      duration: 7000, 
    },
    
  ];
  

  const changeSlide = (newIndex) => {
    if (newIndex > srcVideos.length) {
      setSlideIndex(1); 
    } else if (newIndex < 1) {
      setSlideIndex(srcVideos.length); 
    } else {
      setSlideIndex(newIndex); 
    }
  };
  
  const startSlideTimer = () => {
    clearTimeout(slideTimeout.current);
  
    const currentDuration = srcVideos[slideIndex - 1]?.duration || 3000; 
  
    slideTimeout.current = setTimeout(() => {
      changeSlide(slideIndex + 1); 
    }, currentDuration);
  };
  




  useEffect(() => {
    // GSAP Animation for text container scrolling effect
    slidesRef.current.forEach((slide) => {
      gsap.to(slide.querySelector(".text-container"), {
        y: 150, // Move up
        opacity: 0,
        ease: "power1.out",
        scrollTrigger: {
          trigger: ".hero", // Trigger the animation based on the Hero container
          start: "top top", // Start animating when Hero enters the viewport
          end: "bottom top", // End animating when Hero leaves the viewport
          scrub: true, // Smooth scrubbing effect
        },
      });
    });
  }, []);
  




  

  useEffect(() => {
    const currentSlide = slidesRef.current[slideIndex - 1];
    const currentDot = dotsRef.current[slideIndex - 1];
  
    slidesRef.current.forEach((slide, i) => {
      slide.classList.remove("visible");
      dotsRef.current[i]?.classList.remove("active");
    });
  
    if (currentSlide) currentSlide.classList.add("visible");
    if (currentDot) currentDot.classList.add("active");
  
    const currentVideo = currentSlide?.querySelector("video");
    if (currentVideo) {
      currentVideo.currentTime = 0; 
      currentVideo.play();
      currentVideo.onended = () => {
        changeSlide(slideIndex + 1); 
      };
    }
  
    startSlideTimer();
  
    return () => {
      if (currentVideo) {
        currentVideo.onended = null;
      }
      clearTimeout(slideTimeout.current); 
    };
  }, [slideIndex]);
  






  return (
    <div className="hero">
      <div className="slideshow-container">
        {srcVideos.map((video, index) => (
          <div
            className={`slide fade ${
              index + 1 === slideIndex ? "active-slide" : ""
            }`}
            key={index}
            ref={(el) => (slidesRef.current[index] = el)}
          >
            {video.src.endsWith(".webm") || video.src.endsWith(".mp4") ? (
              <video
                src={video.src}
                muted
                playsInline
                className="slide-image"
              />
            ) : (
              <img src={video.src} alt="slide" className="slide-image" />
            )}
            <div className="text-container">
              <div className="slide-info">
                <h1>{video.title}</h1>
                <p>{video.description}</p>
                <div className="button-container">
                  <div
                    className={`plus-circle ${isTextOpen ? "active" : ""}`}
                    onClick={() => setIsTextOpen(!isTextOpen)}
                  >
                    <span>+</span>
                  </div>
                  {isTextOpen && (
                    <a
                      href={video.link}
                      className={`extra-button ${
                        isTextOpen ? "active" : ""
                      }`}
                    >
                      <button>{video.buttonLabel}</button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>




      <div className="dot-container">
      <button
        className="nav-button-hero left"
        onClick={() => changeSlide(slideIndex - 1)}
      >
          <FontAwesomeIcon icon={faChevronLeft} />
          </button>

      {srcVideos.map((_, index) => (
        <span
          className={`dot ${index + 1 === slideIndex ? "active" : ""}`}
          key={index}
          onClick={() => changeSlide(index + 1)}
          ref={(el) => (dotsRef.current[index] = el)}
        ></span>
      ))}

      <button
        className="nav-button-hero right"
        onClick={() => changeSlide(slideIndex + 1)}
      >
          <FontAwesomeIcon icon={faChevronRight} />
          </button>
    </div>



      
    </div>
  );
};

export default Hero;
