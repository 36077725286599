import React from 'react';
import XP from '../pages/montage/ladetype/Xp';
import PrijsLijst from '../pages/service/PrijsLijst';

// Dynamisch laden van service-componenten
const VeelgesteldeVragen = React.lazy(() => import('../pages/service/VeelgesteldeVragen'));
const FAQDetail = React.lazy(() => import('../components/faq/FAQDetail'));
const Service = React.lazy(() => import('../pages/service/Service'));
const Downloads = React.lazy(() => import('../pages/service/Downloads'));
const Contact = React.lazy(() => import('../pages/service/Contact'));
const Montage = React.lazy(() => import('../pages/montage/Montage'));

const MontageHandleiding = React.lazy(() => import('../pages/montage/MontageHandleiding'));
const AanvoermatenAfvoermaten = React.lazy(() => import('../pages/montage/AanvoermatenAfvoermaten'));
const Ladesystemen = React.lazy(() => import('../pages/montage/Ladesystemen'));
const Scharnieren = React.lazy(() => import('../pages/montage/Scharnieren'));

const Scala = React.lazy(() => import('../pages/montage/ladetype/Scala'));
const Hettich = React.lazy(() => import('../pages/montage/ladetype/Hettich'));
const TBLexcl = React.lazy(() => import('../pages/montage/ladetype/TBLexcl'));

const Grass = React.lazy(() => import('../pages/montage/scharniertype/Grass'));
const SHettich = React.lazy(() => import('../pages/montage/scharniertype/hettich'));
const Sexclusief = React.lazy(() => import('../pages/montage/scharniertype/Exclusief'));

const ServiceRoutes = [
  { path: '/service', element: <Service />, theme: 'light' },
  { path: '/veelgestelde-vragen', element: <VeelgesteldeVragen />, theme: 'light' },
  { path: '/veelgestelde-vragen/:slug', element: <FAQDetail />, theme: 'light' },

  { path: '/prijslijst', element: <PrijsLijst />, theme: 'light' },

  { path: '/downloads', element: <Downloads />, theme: 'light' },
  { path: '/contact', element: <Contact />, theme: 'light' },
  { path: '/montage', element: <Montage />, theme: 'light' },

  { path: '/montage/montagehandleiding', element: <MontageHandleiding />, theme: 'light' },
  { path: '/montage/aanvoermaten-afvoermaten', element: <AanvoermatenAfvoermaten />, theme: 'light' },
  { path: '/montage/ladesystemen', element: <Ladesystemen />, theme: 'light' },
  { path: '/montage/scharnieren', element: <Scharnieren />, theme: 'light' },

  { path: '/montage/ladesystemen/scala', element: <Scala />, theme: 'light' },
  { path: '/montage/ladesystemen/hettich', element: <Hettich />, theme: 'light' },
  { path: '/montage/ladesystemen/exclusief', element: <TBLexcl />, theme: 'light' },
  { path: '/montage/ladesystemen/xp', element: <XP />, theme: 'light' },

  { path: '/montage/scharnieren/grass', element: <Grass />, theme: 'light' },
  { path: '/montage/scharnieren/hettich', element: <SHettich />, theme: 'light' },
  { path: '/montage/scharnieren/exclusief', element: <Sexclusief />, theme: 'light' },

];

export default ServiceRoutes;
